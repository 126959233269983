import React from "react"
import { Container, Card } from "react-bootstrap"
import truck_mounted from "../../public/static/images/products/truck_mounted_anti_smog_gun.jpg"
import semi_automatic from "../../public/static/images/products/semi_automatic.jpg"
import auto_collector from "../../public/static/images/products/auto_collector.jpg"
import refuse_compactor from "../../public/static/images/products/refuse_compactor.jpg"
import dumper_placer from "../../public/static/images/products/dumper_placer_cropped.jpg"
import hook_loader from "../../public/static/images/products/hook_loader.jpg"
import portable_compactor from "../../public/static/images/products/portable_compactor.jpg"
import road_n_street_sweeper from "../../public/static/images/products/road_n_street_sweeper.jpg"
import super_sucker from "../../public/static/images/products/super_sucker.jpg"
import suction_cum_jetting from "../../public/static/images/products/suction_cum_jetting.jpg"
import sewer_suction from "../../public/static/images/products/sewer_suction.jpg"
import nala_cleaning from "../../public/static/images/products/nala_cleaning.jpg"
import desilting from "../../public/static/images/products/desilting.jpg"
import sky_lift from "../../public/static/images/products/sky_lift.jpg"
import mobile_toilet from "../../public/static/images/products/mobile_toilet.jpg"
import portable_toilet from "../../public/static/images/products/portable_toilet.jpg"
import multi_seater_toilet from "../../public/static/images/products/multi_seater_toilet.jpg"
import water_tanker from "../../public/static/images/products/water_tanker.jpg"
import fogging_machine from "../../public/static/images/products/fogging_machine.jpg"
import dustbin from "../../public/static/images/products/dustbin.jpg"

import "../components/styles/image-gallery.css"
import { Link } from "gatsby"

function ImageGallery() {
  const products = [
    {
      name: "Truck Mounted ASG",
      desc: "Anti-smog gun is a canon shaped device that sprays atomized water droplets in the air. Air atomization enables tiny particles or water, which increases the number or droplets and reduces the surface tension of the water.",
      image: truck_mounted,
      link: "/truck-mounted",
    },
    {
      name: "Semi Automatic ASG",
      desc: "Anti-smog gun is a canon shaped device that sprays atomized water droplets in the air. The objective of Anti Smog Gun is to remove dust particles from the air so that quality of air is improved to reach the permissible level of ambient air quality standards. We are the leading manufacturer and supplier of Anti Smog Gun in Manual, Semi-Automatic and Automatic mode in different throwing capacities.",
      image: semi_automatic,
      link: "/semi-automatic",
    },
    {
      name: "Auto Collector",
      desc: "Auto collector equipments can dump the garbage on the ground, as well as director into a collector and also in the refuse collector.",
      image: auto_collector,
      link: "/auto-collector",
    },
    {
      name: "Refuse Compactor",
      desc: "Refuse compactors are heavy capacity of garbage loaders in its product range which is used for storing the large amount of waste as well as compact the same in a effective manner, also use for lifting or unloading the bins directly into its rear hopper.",
      image: refuse_compactor,
      link: "/refuse-compactor",
    },
    {
      name: "Dumper Placer",
      desc: "Dumper placer is also known as skip loader which is used fo carrying the different size of garbage containers or bins from one place to another. These are being manufactured with high strength, reliability, superior quality & utilization for its end use.",
      image: dumper_placer,
      link: "/dumper-placer",
    },
    {
      name: "Hook Loader",
      desc: "Hook-Loader Facilitates Safe and Hygienic Collection, Storage and Large Volume of Garbage Transportation With Fast Operation.",
      image: hook_loader,
      link: "/hook-loader",
    },
    {
      name: "Portable Compactor",
      desc: "Portable Compactors are supplied in roll-on/roll-off (hook lift) formats, to suit customer’s transport requirements and with integrated or external bin lifts. With compaction ratios of around 5:1 a portable compactor can be hold much more waste than a standard skip, meaning many fewer collections for the waste to be disposed.",
      image: portable_compactor,
      link: "/portable-compactor",
    },
    {
      name: "Road Street Sweeper",
      desc: "Our range of products include Road Sweeping Machine such as Mechanical Sweeping Machine, Tractor Attach Road Sweeping Machine and Truck Mounted Road Sweeping Machine.",
      image: road_n_street_sweeper,
      link: "/road-street-sweeper",
    },
    {
      name: "Super Sucker",
      desc: "The Inventa Energy Pvt. Ltd. Super Sucker Machine is designed with the latest technology and Manufactured with strictest adherence to the highest.",
      image: super_sucker,
      link: "/super-sucker",
    },
    {
      name: "Suction Cum Jetting",
      desc: "Suction cum Jetting is a Combination Machine, which combines High Pressure Sewer Jetting Operation (JetMan) as well as Suction Operation with Blowback System (GullyMan). This Machine can carry out both the operations of Jetting i.e cleaning of underground drain lines as well as Suction i.e sucking of sludge from the chambers.",
      image: suction_cum_jetting,
      link: "/suction-cum-jetting",
    },
    {
      name: "Sewer Suction",
      desc: "Sewer Suction cum jetting machine is used for cleaning of sewer lines by sucking out the dirt from chamber into its tank as well as clear the blockage / choke of sewer lines by high capacity jetting throw of water.",
      image: sewer_suction,
      link: "/sewer-suction",
    },
    {
      name: "Nala Cleaning",
      desc: "We are Manufacturing highly productive Nala Cleaning Machine that are used to providing the cleaning facility of open Nala & other waste yard. We are Manufactured with high Quality Materials, reliability, superior hydraulic system & utilization for its end use.",
      image: nala_cleaning,
      link: "/nala-cleaning",
    },
    {
      name: "Desilting",
      desc: "Desilting Machine is developed on a three-wheeler and on TATA ACE. Used for effective and efficient cleaning of silt from manholes and wet wells/sump house, our quality machines have helped us garner a rich clientele in the market.",
      image: desilting,
      link: "/desilting",
    },
    {
      name: "Sky Lift",
      desc: "Sky lift is an also known as automatic lifting machine for maintenance purposes in different sections such as municipalities works, Highways, Building Glass Cleaning & Constructions etc.",
      image: sky_lift,
      link: "/sky-lift",
    },
    {
      name: "Mobile Toilet Bio",
      desc: "Eco-Friendly Mobile Toilet Van. The offered toilet finds wide application use in the trade fair. Furthermore, the toilet van is manufactured using quality material and approved technologies.",
      image: mobile_toilet,
      link: "/mobile-toilet",
    },
    {
      name: "Portable Toilet",
      desc: "We Are Manufacturing A Wide Range of Fully Furnished Mobile Portable Toilets & Bio Toilets as per DRDO Norms That are Used to Providing The Public Facilities At Mega Fairs, Public Festivals, Exhibitions & Municipalities Use Etc.",
      image: portable_toilet,
      link: "/portable-toilet",
    },
    {
      name: "Multi Seat Toilet",
      desc: "Being a quality-centric company, we are engaged in offering a superior quality Mobile Toilet Van 10, 8, 6, 4 and 2 Seater. Our offered van is precisely manufactured using modern technology by our team of qualified professionals who have vast knowledge & experienced.",
      image: multi_seater_toilet,
      link: "/multi-seater-toilet",
    },
    {
      name: "Water Tanks",
      desc: "We are manufacturer & supplier of premium quality SS / MS Water Storage Tank. Latest production techniques, use of excellent raw material combined with innovative styling & designing has made this product reach the pinnacle where it is today.",
      image: water_tanker,
      link: "/water-tanker",
    },
    {
      name: "Fogging Machine",
      desc: "Leading Trader and Supplier of Fogging Machine such as Handy Fogging Machine, Vehicle Mounted Fogging Machine and Portable Fogging Machine",
      image: fogging_machine,
      link: "/fogging-machine",
    },
    {
      name: "Dustbins",
      desc: "As one of the leading entity of this domain, we are engrossed in providing supreme quality Dust Bin. The offered bin finds wide application use in hotels, offices, restaurants, malls, parks and other similar places for keeping the environment neat and clean.",
      image: dustbin,
      link: "/dustbin",
    },
  ]

  return (
    <section id="image-gallery">
      <Container fluid="md">
        <div className="image-gallery-card-div">
          {products.map(prod => (
            <Card
              bg={"dark"}
              text="light"
              style={{ width: "16rem", marginBottom: "2rem" }}
            >
              <Card.Img
                variant="top"
                src={prod.image}
                style={{ height: "160px" }}
              />
              <Card.Body>
                <Card.Title>{prod.name}</Card.Title>
                <hr />
                <Card.Text>
                  {prod.desc.substring(0, 100)}...{" "}
                  <Link to={prod.link} style={{ display: "inline-block" }}>
                    know more
                  </Link>
                </Card.Text>
                {/* <Card.Link href={prod.link}>Know More</Card.Link> */}
                {/* <Card.Link href="#">Another Link</Card.Link> */}
              </Card.Body>
            </Card>
          ))}
        </div>

        {/* <div className="image-gallery-div">
          {products.map(img => (
            <Image
              style={{ width: "250px", height: "200px", paddingBottom: "30px" }}
              src={img.image}
              fluid
            />
          ))}
        </div> */}

        {/* <Carousel indicators={false} style={{}}>
          {products.map(prod => (
            <Carousel.Item>
              <Image src={prod.image} fluid />
            </Carousel.Item>
          ))}
        </Carousel> */}
      </Container>
    </section>
  )
}

export default ImageGallery
