import React from "react"
import { Container } from "react-bootstrap"
import ImageGallery from "../components/image_gallery"
import Layout from "../components/layout"

function Gallery() {
  return (
    <Layout>
      <section style={{ padding: "40px 0" }}>
        <section>
          <Container fluid="md">
            <div>
              <h2 id="home-headings">Our Products</h2>
              <hr id="home-hr" />
            </div>
            <p>
              Having a 15 years of hard core marketing experience for selling
              industrial and commercial cleaning machines as well as waste
              handling equipment. we name of “Inventa Energy Pvt. Ltd.” always
              strives for providing the best solution for the need of the
              clients and are always ready to take the new challenges. now
              company is marketing all its product under its popular brand
              “Inventa Energy Pvt. Ltd.”. Company owns the state of the art
              infrastructure which helps the company for supplying the quality
              range of products.
            </p>
          </Container>
        </section>

        <ImageGallery />
      </section>
    </Layout>
  )
}

export default Gallery
